@import './variables';

.site-logo-container {
  width: 100%;
  position: relative;
}

.site-logo {
  position: absolute;
  left: 50px;
  top: 50px;
  z-index: 1;

  @media (max-width: 768px){
    left: 20px;
    top: 20px;
  }
}

.site-logo-img {
  display: inline-block;
  width: 140px;
  height: 60px;
  background-image: url(/images/default/svg/logo/logo--white.svg);
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;

  @media (max-width: 768px){
    width: 100px;
  }
}

body {
  background: $body-background;
}

main {
  min-height: calc(100vh - 239px);
}